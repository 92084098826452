import styled,{ keyframes } from 'styled-components';
import { borderRadius5, borderRadius9, borderRadius22,borderRadius50,borderRadius18, borderRadius11,borderRadius30, borderRadius50percent } from '../global/Variables.js';



const opacityKeyframes = keyframes`
  from {
opacity:0;
  }

  to {
    opacity:1;
  }
`;

export const Transition1 = styled.div`
    
    &:not(.plugin) {
        opacity:0;

        animation: ${opacityKeyframes} 2s ease forwards;
    }
`;
export const Transition2 = styled.div`

    &:not(.plugin) {
        opacity:0;
        animation: ${opacityKeyframes} 2s ease forwards;
        animation-delay: 0.7s;
    }
`;
export const Transition3 = styled.div`
    &:not(.plugin) {
        opacity:0;

        animation: ${opacityKeyframes} 2s ease forwards;
        animation-delay: 1.4s;
    }
`;



export const GlobalButtonFinalSmall = styled.button`
    border-radius: ${borderRadius5};
    -webkit-font-smoothing: antialiased;
    min-height: 13px;
  border:none;
  color: #444;
  background:transparent;
    outline: none;
    //border: none;
    transition: all 0.3s ease-out;
  margin-bottom:5px;


    text-align: center;
    /* Buton/Text Small */
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 5px 18px;
    svg{
    transition: all 0.3s ease;
        color:#3D55D7 !important;
    }
  span{
    font-size: 11pt !important;
  }
    &:hover,
    &:focus,
    &:active {
     // background: lightgray;
        //background: #2f2f2f;
      background:lightgray;
        outline: none;
      cursor:pointer;
        svg{
           color:white !important;
        }
    
    }
    
    .noBorder{
        border:none;
        color:#3D55D7  !important;
        svg{
            color:#3D55D7  !important;
        }
        &:hover{
            background:transparent !important;
            svg{
                color:#3D55D7  !important;
            }
        }
    }
`;

export const GlobalButtonFinal = styled.button`
    border-radius: ${borderRadius5};
    -webkit-font-smoothing: antialiased;
    height: 36px;
    padding: 4px 33px;
  border:none;
  color: #444;
  background:rgba(242,242,247);

    outline: none;
    transition: all 0.3s ease;
  margin-bottom:5px;
  font-size: 18px;
  span{
    font-size: 11pt !important;
  }
    &:hover,
    &:focus,
    &:active {
      background:lightgray;
        outline: none;
      cursor:pointer;
    
    }
    
    &.small{
        width:80%;
        height: 36px;
        padding: 5px 36px;
        margin:auto auto;
    }

    &.smallest{
        height: 28px;
        padding: 0px 18px;
        margin:auto auto;
        svg{            
            position: relative;
            height: 14px;
            top: -2px;
            margin-right: 5px;
            path{
                transition:all 0.3s ease;
                fill: #3D55D7 !important;
            }
        }
        span{
            font-size: 14px!important;
            font-weight:300;
            top: -1px;
            position: relative;
            color:#3D55D7;
        }
        &:hover{
            svg{
                path{
                    fill:white !important;
                }
            }
        }
    }

    &.smallestRemove{
        height: 28px;
        padding: 0px 18px;
        margin:auto auto;
        svg{
            position: relative;
            height: 14px;
            top: 4px;
            margin-right: 5px;
            display:inline-block;
            float:left;

            path{
                transition:all 0.3s ease;
                fill: #3D55D7 !important;
            }
        }

        span{
            display:inline-block;
            float:left;
            font-size: 14px!important;
            font-weight:300;
            top: -2px;
            position: relative;
            color:#3D55D7 !important;
        }
        &:hover,
        &:focus{
            span{
                color:white !important;
            }
            svg{
                path{
                    fill:white !important;
                }
            }
        }
    }

    &.linkAccount{
      svg{
          height: 1em;
          color:white;
          path{
              fill:white;
          }
      }
    }
`;


export const GlobalButtonSave = styled(GlobalButtonFinal)`
  background: rgb(61,85,215);
  color:white;
`;


export const LoginButton = styled.button`
    border-radius: ${borderRadius9};
    font-size: 12pt;
    -webkit-font-smoothing: antialiased;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
  background: transparent;
  border: thin solid #888;
  color: #444;
    //margin-right: 15px;
  //border:1px solid black;
  //  color: white;
    outline: none;
    //border: none;
    transition: all 0.3s ease;
  margin-bottom:5px;
    &:hover,
    &:focus,
    &:active {
      background: lightgray;
        //background: #2f2f2f;
        outline: none;
      cursor:pointer;
    
    }
`;

export const ModalButton = styled(LoginButton)`
         //background:#6C757D;
  &.delete{
    background: #b30d0d;
    color: white;
    float:right;
  }
`;


export const MainHeadline = styled.h1`
    font-size: 50px;
    font-weight:bold;
`;

export const SubHeadline = styled.h2`
    opacity:0.5;
    font-size: 40px;
  margin-top: 60px;
  margin-bottom: 60px;
`;



export const MainMenuOpenBoxListItem = styled.li`
       padding-top:15px;
       padding-bottom:15px;
       max-width:95%;
        &:not(:last-child){
        border-bottom:1px solid black;
        }
`;

export const RenderModalWrapper = styled.div`
  
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
    z-index: 999999999;
    transition: all 0.3s ease !important;

    
    &.hideModal{
        opacity:0;
        z-index:0;
        display: none;
    }
    &.showTransition{
        opacity:1;
        transition: all 0.3s ease !important;
        >div{
            transform:scale(1);
        }
    }
    &.hideTransition{
        opacity:0;
        transition: all 0.3s ease !important;

    }

    &.hiddeComplete{
        display:none !important;
    }


    .renderDetail {
        width: 100%;
       // position: absolute;
        top: 0;
        left: 0;

        transform: translateY(250%);
        transition: all 0.3s ease;
        padding: 40px 20px;
        text-align:left !important;
        span{
            color: var(--black-20, #CCC) !important;
            font-family: "Nunito Sans";
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 300 !important;
            line-height: normal !important;
            text-align:left !important;
        }
        h2{
            color: var(--White, #FFF) !important;
            font-family: "Nunito Sans";
            font-size: 24px  !important;
            font-style: normal  !important;
            font-weight: 400  !important;
            line-height: normal;
            border-bottom:1px solid #999;
            padding-bottom:10px !important;
            margin-bottom: 10px !important;

        }
        button{
            margin-left:0;
        }
        &.pullup{
            transform: translateY(0);
        }

        .renderDetailThumb{
            width:40%;
            text-align:center;
            top: 0;
            display: inline-block;
            float: left;

            img{
                position:relative;
                margin:auto auto;
                right:unset ;
                max-height: 500px;

                @media(max-width:767px){
                 
                    max-height: 415px;
                }
            }

            .versionSwitcher{
                height:70px;
                margin-top:50px;
                img{
                    max-height:70px;
                }

                .version{
                    width:60px;
                    display:inline-block;
                    span{
                        text-align:center !important;
                        margin-bottom:15px;
                        opacity:0;
                        transition:all 0.3s ease;
                    }
                    .dot{
                        width:5px;
                        height:5px;
                        opacity:0;
                        background:white;
                        border-radius:30px;
                        margin:auto auto;
                        margin-top:15px;
                        transition:all 0.3s ease;
                    }
                    &:hover{
                        cursor:pointer;
                        .dot{
                            opacity:0.5;
                        }
                        span{
                            opacity:0.8;
                        }
                    }
                }
            }
            @media(max-width:991px){
                width:30% !important;
            }
            @media(max-width:767px){
                width:100% !important;
            }

         
            
            //@media(max-width:991px){
            //    //width:100%;
            //}
        }

        >div{
            width: 560px;
            margin-top:15px;
            //border-top:1px solid lightgray;
            display: table;
            position: relative;
            height: 240px;
            img{
                max-width: 100%;
                max-height: 282px;
                position: absolute;
                top: 0;
                right: 0;
            }
            @media(max-width:991px){
                width:60%;
            }
            @media(max-width:767px){
                width:100% !important;
                height: 420px;
            }

        }
        //renderDetailInfo
        //renderDetailImage

        .resolutionBox{
            .description{
                width:45%;
                float:left;
                display:inline-block;
            }
            .buttons{
                width:50%;
                float:right;
                display:inline-block;
                text-align:center !important;
                button{
                    min-width:260px;

                    &:hover{
                        img{
                            filter:invert(0) !important;
                        }
                    }
                }
                span{
                    text-align:center !important;
                }
            }
            @media(max-width:767px){
                .description{
                    width:100%;

                }
                .buttons{
                    width:80%;
                    margin:auto auto;
                    text-align:center;
                    button{
                        float:none;
                    }

                }
            }
        }

        .renderDetailDescription{
            button{
                text-align:left;
            }

            .coin{
                margin-top:3px;
                margin-right:0 !important;
            }
        }
        
        @media(max-width:991px){
            margin-top:30px;
        }
        @media(max-width:560px){
            margin-top:0px;
        }
    }

    .newRender {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 5px;
        transform: translateY(250%);
        transition: all 0.3s ease;
        >div{
            input:not(:first-child){
                margin-left:5px;
            }
            label{
                margin-left:5px;
            }
        }
        h5{
            margin-top:5px;
            padding-bottom:0;
            font-weight:bold !important;
        }
        &.pullup{
            transform: translateY(0);
        }


        .tableContent{
            height:250px;
            overflow-y:scroll;
            overflow-x: hidden;
        }
        th,
        td{
            width:25%;
            display: inline-block;
            padding:5px;
        }
        table {
            font-family: 'Nunito Sans', sans-serif !important;
            border-collapse: collapse;
            width: 100%;
        }

        td, th {
            //border: 1px solid #dddddd;
            text-align: left;
            //padding: 8px;
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }

        .priceBox{
            width: 18%;
        }
        .qualityBox{
            width: 22%;
        }
        .typeBox{
            width: 18%;
        }
        .typeBoxMain{
            width: 16%;
        }
        tr {
            display: block;

            transition: all 0.3s ease;
            //margin-left: 0;
            //padding: 10px;
            border-bottom: 1px solid lightgray;

            &.green {
                background: #b6ffb6;
            }

            &.pending {
                background: #fff548;
            }

            &.queued {
                background: #ffcb5b;
            }
            &.queued_for_render{
                background: #ffcb5b;
            }
            &.queued_for_scene_prepare{
                background: #ffcb5b;
            }
            &.preparing_scene{
                background: #ffcb5b;
            }

            &.error {
                background: #ff6767;
            }
            &.canceled {
                background: #ff6767;
            }

            &.completing {
                background: #1ceaac;
            }

            &.rendering {
                background: #4141b7;
            }

            &:hover {
                background: lightgray;
                cursor:pointer;
            }
        }
    }

    .renderJobs {
        transition: all 0.3s ease;
        transform: translateY(0);
        &.hideup{
            transform: translateY(-150%);
        }
        table {
            font-family: 'Nunito Sans', sans-serif !important;
            border-collapse: collapse;
            width: 100%;
        }

        td, th {
            //border: 1px solid #dddddd;
            text-align: left;
            //padding: 8px;
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }


        .tableContent{
            height:250px;
            overflow-y:scroll;
            overflow-x: hidden;
        }
        th,
        td{
            width:25%;
            display: inline-block;
            padding:5px;
        }

        .priceBox{
            width: 18%;
        }
        .qualityBox{
            width: 22%;
        }
        .typeBox{
            width: 18%;
        }
        .typeBoxMain{
            width: 16%;
        }
        tr {
            transition: all 0.3s ease;
            //margin-left: 0;
            //padding: 10px;
            border-bottom: 1px solid lightgray;

            &.green {
                background: #b6ffb6;
            }

            &.pending {
                background: #fff548;
            }

            &.queued {
                background: #ffcb5b;
            }
            &.queued_for_render{
                background: #ffcb5b;
            }
            &.queued_for_scene_prepare{
                background: #ffcb5b;
            }
            &.preparing_scene{
                background: #ffcb5b;
            }

            &.error {
                background: #ff6767;
            }
            &.canceled {
                background: #ff6767;
            }

            &.completing {
                background: #1ceaac;
            }

            &.rendering {
                background: #4141b7;
            }

            &:hover {
                background: lightgray;
                cursor:pointer;
            }
        }

    }
    

    #packshotModal{
        // width:980px;
        max-width:unset !important;
        background: transparent;
        color:white !important;

        //height: fit-content !important;

        display: block !important;
       // width: 980px;
      //  padding-bottom: 44px;
        flex-direction: column;
        align-items: flex-start;
        gap: 44px;
        //max-height: unset !important;
        overflow: unset !important;
        border:none !important;
        position:absolute !important;

        top:0 !important;
      //  padding-top:70px !important;
        box-shadow: none !important;
     /*   padding-left:0;
        padding-right:0;*/

        overflow: auto !important;
        height: 100vh !important;
        max-height: 100vh !important;
        left:50%;
        transform:translateX(-50%) !important;

        width: 1030px;
        max-width: 90%;
       // background-color: white;
        border-radius: 8px;
        padding: 20px;
        padding-top: 80px;
        margin-bottom: 30px; /* Margin odspoda */
        
   
        
        .modal-content{
            height: auto;
            max-height: calc(100vh - 70px);
            overflow-y: auto;
            
            @media(max-width:991px){
                margin-bottom:80px;
            }
        }

        button{

            svg{
                margin-right:10px;
                height:18px;
            }
        }
        .packshotsHeader{
            h4{
                display:inline-block;
                width:fit-content;

                color: var(--White, #FFF);
                leading-trim: both  !important;
                text-edge: cap  !important;
                font-family: "Nunito Sans";
                font-size: 25px !important;
                font-style: normal;
                font-weight: 300  !important;
                line-height: normal;
                &.withHover{
                    &:hover{
                        cursor:pointer;

                    }
                }
            }
            .halfing{
                height: 20px;
                display:inline-block;
                margin-left:15px;
                margin-right:15px;
            }
            @media (max-width:560px){
               max-width:50%;
               h4{
                   margin-bottom:5px !important;
               }
                
            }
        }
        .packshotsHeaderRight{
            display:inline-block;
            width:fit-content;
            float:right;

            button{
                @media (max-width:991px){
                    position: absolute;
                    min-width: 180px;
                    right: 0;
                    &:nth-child(2){
                        top:50px;
                    }
                }
                @media (max-width:400px){
                    position: absolute;
                    min-width: 125px;
                    font-size:13px;
            
                
                }
            }
            
        }

        .creditsHeader{
            color: var(--black-20, #CCC);

            /* Form/Input */
            font-family: "Nunito Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            b{
                color: var(--black-20, #CCC);
                font-family: "Nunito Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

         @media (max-width:991px){
             width: fit-content;
             position: absolute;
             left: 0px;
             top: 50px;
             
         }
            @media (max-width:560px){
               
                top: 90px;

            }
            @media (max-width:400px){

                top: 100px;

            }
        }

        .packshotsList{

            .renderList{
                display:inline-block;
            }
            .renderDetail{
                display:none;
                background:#666;
                border-radius:${borderRadius11};

                &.pullup{
                    display:block;
                }
            }

            .hide{
                display:none;
            }

            
                @media (max-width:991px){
                 margin-top:30px;
                }
            @media (max-width:560px){

                top: 60px;

            }
            
        }

        @media(max-width:991px){
            max-width:90% !important;
            overflow-y: hidden !important;
            max-height: 100% !important;
            height:100% !important;
            padding-top: 30px;
            .modal-content{
                z-index: 999999999999999999999;
            }
            .packshotsList{
                padding-bottom:100px;
            }
        }
        
    }
`;
export const ModalsWrapper = styled.div`
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:rgba(0,0,0,0.5);
    z-index: 999999999;
     transition: all 0.3s ease !important;
  opacity:1;
  display: flex;
  box-sizing: border-box;
  &.alertCheck{
    z-index: 999999999999; 
  }
  
    &.packshots{
        background:rgba(0,0,0,0.9);
        position:absolute;
        display:block;
        height:100%;
        width:100%;
    }
      @media screen and (max-height: 800px) {

       overflow-y:scroll !important;
       
    }
    
      &.hideModal{
        opacity:0;
        z-index:0;
        display: none;
      }
  justify-content: center;
  align-items: center;
    &.colorsWrapper{
        justify-content: left;
        //align-items: left;
    }

  //opacity:0;
  //display:block;
  .deleteCheck{
    opacity:1;
    position:absolute;
    transition: all 0.3s ease !important;
  }
  &.showTransition{
    opacity:1;
    transition: all 0.3s ease !important;
    >div{
      transform:scale(1);
    }
  }
  &.hideTransition{
    opacity:0;
    transition: all 0.3s ease !important;    
  
  }

  &.hiddeComplete{
    display:none !important;
  }

`;
export const ModalWindowRightSide = styled.div`
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  float: right;
  right: 0;
  background:white;
    
    padding-top:45px;
    
/*    &.isPackshots{
        background:#666;
        
        button,p,span,h3{
            color: white !important;
        }
        svg{
            fill:white !important;
            color: white !important;
        }
    }
    */

    >div{
        position:relative;
    }


  .modal-header{
      position:relative;
    svg{
        
      padding:5px;
        border-radius: ${borderRadius30};
      transition:all 0.3s ease;
        height:30px;
        width:30px;
        right:0;
        position:absolute;
        top:5px;
    }
    svg:hover{
      background: lightgray;  
        cursor:pointer;

    }
    
  }
    ul{
        padding:0 !important;
    }
 li{
   border-bottom:1px solid lightgray;
   padding-top: 13px;
   padding-bottom: 0;
   padding-left:15px;
   
   
   &.disabledFilter{
   background:lightgray;
   }
   &:hover{
     .content-buttons{
       opacity:0.7;
     }
   }
   img,
   p{
     display: inline-block;
   }
   .logoInside{
     display: inline-block;
     text-align: center;
     height: 35px;
       border-radius: ${borderRadius50};
     overflow: hidden;
     width: 35px;
     border: 1px solid lightgray;
     float:left;
     position:relative;
     margin-top: -6px;
     margin-right:15px;
     svg{
       left:50%;
       position:absolute;
       top:50%;
       transform:translate(-50%,-50%);
     }
   }
   input{
     width:20px;
     height:20px;
     //top: 0;
     //left: 0;
     top: 5px;
     //margin-top: 10px;
     position: relative;
     //width: 100%;
     //cursor: inherit;
     //height: 100%;
     //margin: 0;
     //opacity: 0;
     //padding: 0;
     //z-index: 1;
     //position: absolute;

   }
 }
 .logoInput{
   width:35px;
   display:inline-block;
   
   
 }
  .content{
    width:71%;
    display:inline-block;
  }
  .content-buttons{
 
    top:1px;
    width: 60px;
    display:inline-block;
    position: relative;
    opacity:0;
    transition: all 0.3s ease;
    button{
        border-radius: ${borderRadius30};
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #4c4c4c !important;
      position: relative;
      background: transparent;
      font-size: 16px;
      font-weight: 700;
      z-index: 900;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;

      border:none !important;
      display:inline-block;
      outline:none!important;
      svg {
      color: #4c4c4c !important;
        }
      &:hover,.active{
     
          background: lightgray;
        
      }
    }
  }
  
  .MuiSvgIcon-root-90 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }

  
  >div{
    width: 500px;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 32px;
  }
  .modal-buttons{
    margin-top:30px;
  }
  .modal-content{
    overflow-y:auto;
    ul{
      list-style-type:none;
      li{
        img{
          height:100%;
        }
      }
    }
  }
    @media (max-width:767px){
        //max-width:350px;
        >div{
            max-width:400px;
            >button{
                width:fit-content !important;
            }
        }
    }
    @media (max-width:400px){
        max-width: 90%;
    }
    
` ;

export const ModalWindow = styled.div`
 // position: absolute;
  background: white;
  max-width: 600px;
  border: 1px solid gray;
  //max-height: 50%;
  max-height: unset;
  //  opacity:1;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  border-radius: ${borderRadius22};
  box-shadow: rgb(0 0 0 / 12%) 4px 4px 8px 2px, rgb(0 0 0 / 4%) 0px 0px 0px 3px;
  overflow: hidden;
    top:50%;

  transition: all 0.2s ease !important;
    transform: translate(0,-50%) !important;
    position:absolute;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  flex-shrink: 1;
  width:600px;
  align-items: center;
  transform-origin: center top;
  display:flex;
    
    &.youtubevideo{
        min-width:800px;
        
        @media(max-width:800px){
            max-width:90%;
            min-width:unset;
            iframe{
                max-height:300px;
            }
            button{
                margin-top:60px !important;
            }
        }
        
    }


    .deleteGroupsCheck{
        button{
            font-size:13px !important;
            padding:4px 23px !important;
        }
        
    }
    
    .embedLink{
        textarea{
            transition:all 0.3s ease;
            &:focus{
                outline:none;
            }
            &:hover,
            &:focus{
                border:1px solid #3D55D7 !important;
            }
        }
    }
    
    #dialogDone,
    #dialogDone2{
        @media (max-width:550px){
            width: fit-content;
            display: block;
           
            margin-left: unset !important;
            margin: auto auto !important;
            margin-top:5px !important;
          
        }
    }
    .editGroups img{
        margin-left: 10px;
    }
    &#buyCreditsProductDialog{
        height: 600px;
        >div{
            height: 600px;
            display: grid;
        }
        @media (max-height: 600px){
            height: 400px !important;
            >div{
                height: 400px !important;
           
            }
        }
    }
    .uploading-loading-inner{
        padding-top: unset;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;

        img{
            left:50%;
            top:50%;
            max-width:50px;
            position:fixed;
            transform: translate(-50%, -50%);
        }

    }
    
    .iconWithoutLogo{
        margin-top: 15px;
        margin-bottom: 15px;
        width: 80px;
        height: 80px;
        border-radius: ${borderRadius50percent};

        border: 1px solid lightgray;
        max-width: 100%;
        color:gray;
        position:relative;
        svg{
            //left:30%;
            //top:28%;
            //width:40%;
            //height:40%;
            //position:relative;

            left: 50%;
            top: 28%;
            width: 35px;
            height: 35px;
            position: absolute;
            transform: translateX(-50%);
            
        }
   
     
    }
  &#duplicateDialog{
    max-width: 1000px;
    width:1000px;
  }
    .uploading-loading{
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        z-index: 99;
        border-radius: ${borderRadius22};
        img{
            max-width:50px;
        }
        >div{
            width:fit-content;
            height:fit-content;
            top:50%;
            position:absolute;
            left:50%;
            transform:translate(-50%,-50%)
        }
        &.hide{
          display:none;
        }
    }
    &.chooseShrink{
        width:900px;
        max-width: unset;
        max-height: 85%;
        overflow:hidden;
    }


.uploading-loading{
    svg,img{
        height:64px;
        margin-top:20px;
        margin-bottom:20px;
    }
}
    
    .fileUploadEditor{

        border-radius: ${borderRadius11};
    border-color: rgba(102, 102, 102, 0.5) !important;
    border-style: dashed !important;
    border-width: 1px!important;
        
        span{
            color: var(--black-60, #666);
            text-align: center;
            /* Dialog/Body */
            font-family: "Nunito Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        button{
            height: 36px;
            padding: 5px 36px;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
           
            border-radius: ${borderRadius18};
            border: none;
            color: white;
            background: var(--Buton-Active, #3D55D7);
            display: block;
            width: fit-content;
            margin: auto auto;
            margin-top: 10px;
            font-weight: 300 !important;
        }
        
        &.active{
            border: 30px solid red !important;
        }
        &.activeDrag{
            background: rgba(0,100,255,0.1);
        
        }
        
    }
        
    &.colorsList{
        width:300px;
        display:block;
        text-align:left;
        align-items:left;
        left: 155px;
    
        h5 {
            //    margin-top: 20px;
            margin-bottom: 12px !important;
            padding-bottom: 0 !important;
            -webkit-font-smoothing: antialiased;
            color: rgba(0,0,0,0.7);
            font-family: "Nunito Sans";
            font-size: 20px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: normal !important;
        }
        
    }
    .openingTypeList{
        list-style-type: none;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;



        li{
            margin-top: 10px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
      
            border-radius: ${borderRadius5};
            
            padding-left:0 !important;
            margin-top: 0 !important;
            &:hover{
                background:transparent !important;
            }
        }
        input[type=color]{

            width:28px !important;
            height:28px !important;
            padding: 0 !important;

            margin-left:0 !important;
            border: none !important;
            max-width: 100% !important;
            margin-top: 0 !important;
            -webkit-transition: all 0.3s ease !important;
            transition: all 0.1s ease !important;
            left: 0 !important;
            position: relative !important;
            min-height: unset !important;
            border-radius: ${borderRadius22} !important;
            
            //   padding-bottom: 5px!important;
            //   padding-top: 5px !important;
            margin-right:10px !important;
            top:2px;
            overflow: hidden;
            &:hover{
                outline: 2px solid gray;
                outline-offset: 2px;
            }

        }
        button{
            width:28px !important;
            height:28px !important;
            padding: 0 !important;

            margin-left:0 !important;
            border: none !important;
            max-width: 100% !important;
            margin-top: 0 !important;
            -webkit-transition: all 0.3s ease !important;
            transition: all 0.1s ease !important;
            left: 0 !important;
            position: relative !important;
            min-height: unset !important;
            border-radius: ${borderRadius22} !important;
            margin-right:10px !important;
            top:2px;

            &:hover,
            &.active{
                outline: 2px solid gray;
                outline-offset: 2px;
            }
        }

        span{
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            top: -6px;
            position: relative;
        }
        img{
            height:50px;
            &:hover{
                transform:scale(1.1);
            }
        }
    }
    .colorsList{
        list-style-type: none;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;


        
        li{
            margin-top: 10px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        
            border-radius: ${borderRadius5};
            padding-left:0 !important;
            margin-top: 0 !important;
            &:hover{
                background:transparent !important;
            }
        }
        input[type=color]{
      
                width:28px !important;
                height:28px !important;
                padding: 0 !important;

                margin-left:0 !important;
                border: none !important;
                max-width: 100% !important;
                margin-top: 0 !important;
                -webkit-transition: all 0.3s ease !important;
                transition: all 0.1s ease !important;
                left: 0 !important;
                position: relative !important;
                min-height: unset !important;
            border-radius: ${borderRadius22} !important;
                margin-right:10px !important;
                top:2px;
            overflow: hidden;
                &:hover{
                    outline: 2px solid gray;
                    outline-offset: 2px;
                }
            
        }
        button{
            width:28px !important;
            height:28px !important;
            padding: 0 !important;

            margin-left:0 !important;
            border: none !important;
            max-width: 100% !important;
            margin-top: 0 !important;
            -webkit-transition: all 0.3s ease !important;
            transition: all 0.1s ease !important;
            left: 0 !important;
            position: relative !important;
            min-height: unset !important;
            border-radius: ${borderRadius22} !important;
            margin-right:10px !important;
            top:2px;

            &:hover,
            &.active{
                outline: 2px solid gray;
                outline-offset: 2px;
            }
        }

        span{
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            top: -6px;
            position: relative;
        }
    }
  .packageTypes{
    width:100%;
    margin-top:50px;
    margin-bottom:30px;
    >a{
      //border:1px solid lightgray;
      display:inline-block;
      text-align:center;
      max-width:30%;
      transition: all 0.3s ease !important;

      //width: 300px !important;
      margin: auto auto;
      height: 100px;
      border: 1px solid lightgray;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
      
      &:not(:first-child){
        margin-left: 15px;
      }
      h5{
        //margin-top:15px!important;
        font-size: 16px!important;
      }
      img{
        max-width:100%;
        transition: all 0.3s ease !important;
        //max-width: 300px;
        border: thin solid lightgray;
        top: -25px;
        position: relative;
        left: 10px;
      }
      
      &:hover{
        border-color: midnightblue;
        cursor:pointer;
        //transform:scale(1.05);
      }
    }
  }
  
  .moveThreeGroupsList{
    width:90%;
    margin:auto auto;
    margin-top:15px;
    float:none;
    border-top:1px solid black;
    border-bottom:1px solid black;
    ul{
      list-style-type:none;
      //border: 1px solid black;
      padding:0;
    }
    .moveTreeUL{
      height:200px;
      overflow-y:scroll;
      >li{
        padding-top: 13px;
        padding-bottom: 5px;
        border-bottom:1px solid lightgray;
      }
    }
    .moveTreeULSecondLevel{
      padding-left:30px;
    }
    .moveTreeULThirdLevel{
    padding-left:60px;
    }
    .content-buttons{

      top:3px;
      width: 100px;
      display:inline-block;
      position: relative;
      opacity:0;
      transition: all 0.3s ease;
      button{
          border-radius: ${borderRadius30};
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        color: #4c4c4c !important;
        position: relative;
        background: transparent;
        font-size: 16px;
        font-weight: 700;
        z-index: 900;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
 
        border:none !important;
        display:inline-block;
        outline:none!important;
        svg {
          color: #4c4c4c !important;
        }
        &:hover,.active{

          background: lightgray;

        }
      }
    }
   .papa{
     //border-bottom:1px solid lightgray;
     //padding-top: 13px;
     transition:all 0.3s ease;
     padding-bottom:6px;
     p{
       padding-bottom:0!important;
       margin-bottom:0!important;
       position: relative;
       top: 2px;
     }
     &:hover{
       background:#e8e8e8;
       .content-buttons{
         opacity:0.7;
       }
     }
   }
    li{
      //border-bottom:1px solid lightgray;
      //padding-top: 13px;
      padding-bottom: 0px;
      
      img{

          width: 100%;
      }
  
      img,
      p{
        display: inline-block;
      }
      .logoInside{
        display: inline-block;
        text-align: center;
        height: 20px;

        overflow: hidden;
        width: 20px;
          border-radius: ${borderRadius50};
        //border: 1px solid lightgray;
        float:left;
        position:relative;
          
        margin-top: 5px;
        margin-right:15px;
        svg{
          left:50%;
          position:absolute;
          top:50%;
          transform:translate(-50%,-50%);
        }
      }
      input{
        width:15px;
        height:15px;
        //top: 0;
        //left: 0;
        top: 5px;
        //margin-top: 10px;
        position: relative;
        //width: 100%;
        //cursor: inherit;
        //height: 100%;
        //margin: 0;
        //opacity: 0;
        //padding: 0;
        //z-index: 1;
        //position: absolute;

      }
    }
    .logoInput{
      width:35px;
      display:inline-block;


    }
    .content{
      width:65%;
      display:inline-block;
      text-align: left;
    }
  }
.billinginfo{
left:-7px;
  position:relative;
  input{
    width:47%;
    margin-left:15px;
    margin-top: -6px;
  }
}
//.modal-header{
  h4{
    font-size:22px !important;
    font-weight:400 !important;
    //padding-bottom:30px !important;
  }
  &.center{
    text-align:center;
  }
//}
  span{
    font-weight:300;
    font-size:18px;
  }
&.alert{
  span{
    width: 50%;
    position: relative;
    display: block;
    margin: auto auto;
  }
  .warning{
    color:#C86400;
  }
  .error{
    color:#C80000;
  }
}
  

  .colors{
    button{
      width:30px;
      height:30px;
      margin-top:5px;
      border-radius: ${borderRadius50percent};
      margin-left:5px !important;
      min-height:30px !important;
&:first-child{
  margin-left:0 !important;
}

    }

  }

  .backButton{
    border:none;

    transition: all 0.3s ease;
  
    border-radius: ${borderRadius50percent};
    position:absolute;
    left:-5px;
    top:-45px;
    width:36px;
    height:36px;
    &:hover{
      background:lightgray;
    }
  }
  .shareTab{
    display:none;
    &.active{
      display:block;
    }
  }
.modal-buttons{
  display: block;
  width: 80%;
  margin: auto auto;
  //margin-top:20px;
  height: 45px;
    margin-top: 30px;
  button{

    width: 48%;
  @media (max-width:500px){
    width: 100%;
    margin:0 !important;
    margin-top:15px !important;
    
  }
  }
    
    &.space{
        button:not(:first-child){
            margin-left: 15px !important;
        }
    }
    
 
}

  .editGroups {

    .fileUploader {
      label {
        height: 100%;
        top: 11px;
        margin-left: 0;
        width: 100%;
        min-height:80px;
        max-width:unset;
      }
    }
    img{
      margin-top:15px;
      margin-bottom:15px;
      width:80px;
      height:80px;
  
      border-radius: ${borderRadius50percent};
        
    }
    .colors{
      button{
       outline:none; 
        border:1px solid lightgray;
      }
    }

  }



  .modal-header {
    position: relative;
    //border-bottom: 1px solid lightgray;
    text-align: center;
    height: 30px;
    width: 100%;
    display: inline-block;
    padding-top:5px;

    h3,
    h4 {
      //margin-top: 20px;
      width: 100%;
      display: inline-block;
      //opacity:0.8;
      //top: -5px;
      padding-bottom:0 !important;
      line-height:1 !important;
      text-align: left;
      position: relative;
      //margin-bottom: 5px !important;
      svg {
        margin-right: 10px;
        position: relative;
        top: 0;
      }
    }

  }

  
  .modal-content {
    text-align: left;
    padding-top: 10px;
    h3,
    h4 {
      margin-top: 15px;
 
      
      padding: 0 !important;
      margin-bottom: 5px !important;
    }
    span{
       // opacity:60%;
        color: var(--black-60, #666);
        display:block;
        color: black;
        text-align: center;
        /* Dialog/Body */
        font-family: "Nunito Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        //margin-top: 5px;
        
    }
    //button{
    //  margin-top:20px;
    //  width:40%;
    //}

    .moveThreeGroupsList{
      
    }
  }

  &.center{
    .modal-header {
      height:45px;
      h4 
      ,h3{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 15px !important;
        padding-bottom:5px;

      }
        h4{
            color: var(--black-70, #4C4C4C);
            text-align: center;
            /* Dialog/Title */
            font-family: "Nunito Sans";
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;}
    }
    .modal-content {
      text-align: center;

    }
}
  

  .delete-content {

    h4 {
      text-align: center;
    }

    width: 50%;
    margin: auto auto;
    margin-top: 90px;
  }

  &.hideModal {
    opacity: 0;
    display: none;
  }


    .closeButton{
        height: 30px !important;
        top: -5px !important;
        width:30px;

        right: -5px;
        background: transparent;
        border: none;
        max-width: 100%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;

        position: relative;
        //min-height: 35px;
        border-radius: 22px;
        padding-bottom: 5px;
        padding-top: 5px;
        
        svg{
            color:#4C4C4C;
            color: #4C4C4C;
            height: 19px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);

            path{
                fill:#4C4C4C;
            }
        }
        &:hover{
            background:lightgray !important;
        }
    }
    
  //.closeButton {
  //  width: 30px;
  //  height: 30px;
  //  position: absolute;
  //  background: transparent;
  //  border: none;
  //  right: -5px;
  //  top: -5px;
  //  opacity: 0.5;
  //  transition: all 0.3s ease;
  //
  //  &:hover,
  //  &:focus,
  //  &:active {
  //    opacity: 1;
  //  }
  //
  //  img {
  //    max-width: 100%;
  //  }
  //}


.search-autocomplete{
 
  border-radius: ${borderRadius22};
    
  &:hover{
    
  }
}
.search-autocomplete-icon{
width:15%;
  float:left;
img{
  border-radius:100%;
}
}
  .search-autocomplete-description{
    width:75%;
    float:left;
  }
  //
  //search .wrapper:first-child svg{
  //  display:none;
  //}
  .search-icon{
    display:none;
  }
  .search .wrapper{

    border-radius: ${borderRadius11};
      
    >div{
      min-height: 36px !important;
    }
    .clear-icon{
      margin: 0px 10px 0 0;
    }
  }
  .jiMOeR .wrapper:focus-within {
    transition:all 0.3s ease;
    box-shadow: none !important;
    border-color:#3d55d7;
  }
  .jiMOeR .wrapper:hover,
  .jiMOeR .wrapper:active{
    transition:all 0.3s ease;
    box-shadow: none !important;
    border-color:#3d55d7;
  }
  
//.search .wrapper:first-child input {
//  border: 4px solid red !important;
//}
//
//  // to target the wrapper element
//  .search .wrapper:first-child {
//    border: 4px solid orange;
//  }
  .search{

    
    z-index: 9;
    margin-bottom: 50px;
    // @media screen and (max-height: 800px) {
    //   margin-bottom: 10px;
    // }
  }
  .sharePersonsMyTeamContent{
    // @media screen and (max-height: 800px) {
    //   max-height:100px;
    //   // overflow-y:scroll;
    //   //  overflow-x: hidden;
    // }
  }
  &.share{
    display: table;
  //overflow: visible;
    text-align: center !important;
    //margin-bottom:25px;
    overflow: hidden;
    z-index:9;
  //margin-top:10%;
//
// @media screen and (max-height: 800px) {
//     min-height: 625px;
// }
    .shareInvite{
      textarea{
        height:128px;
        border: 1px solid #dfe1e5;
        width:100%;
        resize: none;
   
        border-radius: ${borderRadius11};
          
        transition: all 0.3s ease;
        outline:none;
        padding:10px;
        &:hover,
        &.active,
        &:active{
          outline:none;
          border: 1px solid #2035a5;
        }
        
      }
    }
    .inviteMessage{
      height:50px;
      span{
        opacity:60%;
        float: left;
        margin-left: 30px;
      }
      input[type=textarea]{
        height:128px;
        border:1px solid rgba(0,0,0,0.6);
      }
 
    }
    .modal-header{
      text-align:center;
    }
    .small-section{
      padding:0;
      border:unset;

      overflow: visible;
    }
    h4{
      text-align: center  !important;
      font-size:22px !important;
      font-weight:700 !important;
    }
    
    .nav-tabs{
      text-align: center;

      display: block;
      width: fit-content;
      margin: auto auto;
      margin-top:15px !important;
      background: rgba(0,0,0,0.15);
      padding: 4px;
    
      border-radius: ${borderRadius22};
        
          padding-bottom: 5px;

    
       li {
        text-transform: none;
       padding: 0 !important;
   
      }
      .nav-link{
            padding-top: 5px !important;
      padding-bottom: 5px !important;
        border:none  !important;
        text-transform: none !important;
        font-weight: 400;
        letter-spacing:0;
        line-height: 26px;
        color:black !important;
          font-size:18px !important;
          
        a{
         letter-spacing:0;
          text-transform: unset !important;
          font-size:18px !important;
       
        }
          //    @media screen and (max-height: 800px) {
          //        font-size:14px !important;
          // }
        &.active,
        &:hover{
          background:white  !important;
        }
      }
    }
    input{
      width:100%;

      border-radius: ${borderRadius11};
        
      font-size:16px;
      padding:5px;
      padding-left:10px;

    }
    
    .infoText{
      display:block;
      max-width:75%;
      margin:auto auto !important;
      margin-top:30px !important;
      margin-bottom:35px !important;
      opacity:80%;
      font-size:18px;
      line-height:22px;
        //  @media screen and (max-height: 800px) {
        //   margin-bottom: 20px !important;
        //   font-size:14px;
        // }
        //
    }
    h3{
      font-size: 18px !important;
      position: relative;
          color: rgba(0,0,0, 0.5);
          // @media screen and (max-height: 800px) {
          //      font-size: 16px !important;
          //   }
            
img{
  margin-right:10px;
}
     &:before {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: rgba(0,0,0,0.15);
        left: 0;
        top: 50%;
        position: absolute;
      }
      &:after {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: rgba(0,0,0,0.15);
        right: 0;
        top: 50%;
        position: absolute;
      }
      
    }

    
  

    
    .others-section{
      margin-top: 30px;
      padding-bottom: 30px;
      
        // @media screen and (max-height: 800px) {
        //     margin-top: 10px;
        //       padding-bottom: 10px;
        // }
        //    
      .person:first-child {
        margin:0;
      }
      text-align:center;

      h3{
        margin-bottom: 0 !important;
        &:before,
        &:after{
          width:225px;
        }
      }
    }

    .public-section{

      .public_link:first-child {
        margin:0;
      }
      .publicLink{
        img{
          width:48px;
          height:48px;
        }
      }
      h3{
        &:before,
        &:after{
          width:200px;
        }
      }
.publicLink{
  width: 100%;
  display: block;
  overflow: visible;
  margin-top: 20px;
  height: 50px;
  >div{
    display:inline-block;
    float:left;
  }
  .personIcon{
    width:15%;
      @media(max-width:550px){
          margin-right:5px;
        min-width:53px;
      }

  }
  .personName{
    width:55%;
    text-align:left;
    padding-bottom: 25px;
    p{
      font-size:16px;
      opacity:80%;
      margin:0;
        word-wrap: break-word;
           // @media screen and (max-height: 800px) {
           //      font-size:15px;
           //  }
        
    }
  }
  .personActions{
    width:30%;
float:right;

      @media (max-width:550px){
          position: absolute !important;
          right: 0px !important;
      }
    button{
      border:none;
      background:transparent;
      transition: all 0.3s ease;
      border-radius: ${borderRadius22};
        
      padding:5px;
      padding-right:15px;
      padding-left:15px;
      font-size:16px;
      color:rgba(0,0,0,0.6);
      float:right;
      
      &:hover,
      &.active{
        color:black;
        background:rgba(0,0,0,0.15);
      }
      
      &.owner:hover,
      &.owner.active{
            color:rgba(0,0,0,0.6);
      background:none !important;
      }
      svg{
        margin-left:3px;
      }
    }

    .sharePersonPopup{
      display:none;
      width: 200px;

    height: 84px;
        margin-top: 40px;
      background: white;
      border-radius: ${borderRadius22};
        
      box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.15);
      z-index: 9;
      position: absolute;

      padding:5px;
      &.active{
        display:block;
      }
      hr{
        margin: 0;
      }
      ul{
        list-style-type:none;
        text-align:left;
        padding:0;
        margin:0;

        li{
          transition:all 0.3s ease;
          font-size:18px;
          padding:5px;
            border-radius: ${borderRadius22};
          padding-left:15px;

          span{
            transition:all 0.3s ease;
            margin-left:10px;
            color:rgba(0,0,0,0.6);
          }
          svg{
            transition:all 0.3s ease;
            color:rgba(0,0,0,0.6);
          }

          &:hover{
              cursor:pointer;
            background:rgba(0,0,0,0.15);
            span,
            svg{
              color:rgba(0,0,0,1);
            }
          }
          &.active{
            color:#3d55d7;
            span,
            svg{
               color:#3d55d7;
            }
          }
        }
      }
    }
  }
}
     
      
    }
    .actions-section{
      margin-top:30px;
      button{
        padding-left:30px !important;
        padding-right:30px !important;
      }
    }
    

  }
    
    @media(max-width:700px){
        max-width:80%;
        
        .packageTypes{
            >a{
                width:38%;
                max-width:unset;
            }
        }
    }

    @media (max-width:500px){
        max-width:80%;
padding-bottom:30px;
        .packageTypes{
            height:400px;
            overflow-y:scroll;
            >a{
                margin-top:40px;
                width:75%;
                max-width:unset;
            }
        }
    }


    &.modal_dialog_small{
        max-width:600px;
        
        .modal-buttons{
            display: block;
            width: 87%;
            margin: auto auto;
            height: 45px;
            margin-top: 30px;
            
            button{
                width: 48%;
                float: left;
               
                &:last-child{
                    margin-left:15px !important;
                }
            }
        }
        
        @media(max-width:600px){
            max-width:95%;
            .modal-content{
                >div{
                    width:100% !important;
                    text-align:center !important;
                }
            }
            .modal-buttons{
                width:100%;
                height: 100px;
                margin-top: 10px;
                button{
                    width:100%;
                    margin-left:0 !important;
                    margin-top:10px;

                    &:last-child{
                        margin-left:0px !important;
                    }
                }
            }
            .fileUploadEditor{
                margin-top: 10px;
            }
        }
    }


    &.modal_dialog_big{
        max-width:1000px;

        .modal-buttons{
            display: block;
            width: fit-content;
            margin: auto auto;
            height: 45px;
            margin-top: 30px;

            button{
                width: 48%;
                float: left;

                &:last-child{
                    margin-left:15px !important;
                }
            }

                @media(max-width:700px){
                    button{
                        float:none !important;
                        &:last-child{
                            margin-left:5px !important;
                        }
                    }
                  
                }
            
        }

        @media(max-width:600px){
            max-width:95%;
            .modal-content{
                >div{
                    width:100% !important;
                    text-align:center !important;
                }
            }
            .modal-buttons{
                width:100%;
                height: 100px;
                margin-top: 10px;
                button{
                    width:100%;
                    margin-left:0 !important;
                    margin-top:10px;

                    &:last-child{
                       // margin-left:0px !important;
                    }
                }
            }
            .fileUploadEditor{
                margin-top: 10px;
            }
        }
    }
    &#editorProductDialog{
        max-height: 88%;
        overflow-y: scroll;
        display: block;
        top: 46%;
        transform: translateY(-50%) !important;
    }
    @media (max-height:700px){
        max-height: 88%;
        overflow-y: scroll;
        display: block;
        top: 46%;
        transform: translateY(-50%) !important;
    }
`;


export const Loader = styled.div`
  z-index: 9999999;
  background: rgba(255,255,255,1);
  position: absolute;
  left: 0;
  top: 5px;

  width: 100%;
  height: 100%;
img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width:100px;
}

transition:all 0.5s ease;

opacity:0;

&.showTransition{
opacity:1;
}
&.hideTransition{
opacity:0;
}
display:block;
&.hiddeComplete{
display:none;
}



`;
